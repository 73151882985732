.menuItemLink {
  width: 100%;
  color: black;
  text-decoration: none;
}

.selectedItem {
  background-color: #1976d2;
  color: #ffffff;

  path {
    fill: #fff;
  }
}
