.split {
  display: flex;
  flex-direction: row;
}

.splitColumn {
  overflow: hidden;
  height: 100%;
  padding: 5px 20px 0 20px;
}
