.editor {
  counter-reset: line;
}

.editor #codeArea {
  outline: none;
  padding-left: 60px !important;
}

.editor pre {
  padding-left: 60px !important;
}

.editor .editorLineNumber {
  position: absolute;
  left: 0px;
  color: #636363;
  text-align: right;
  width: 40px;
  font-weight: 300;
}
