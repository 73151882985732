.dialog {
  height: 100vh;
}

.dialog-title {
  height: 10%;
}

.dialog-content {
  height: 90%;
}

.dialog-footer {
  height: 10%;
}